import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Deep Dish Pizza Crust" />
    <h1>Deep-dish pizza crust recipe</h1>

    <p>I've never been to Chicago but I've had deep dish pizza. This website has a recipe which was
      highly recommended by commenters. Full-credit goes here for the recipe!!</p>

    <p><a href="https://www.foodnetwork.com/recipes/jeff-mauro/true-chicago-style-deep-dish-pizza-5612273">
      True Chicago Deep-Dish Pizza
    </a></p>

    <p>This my own take on the recipe. This came out fantastic!</p>

    <h4>Ingredients</h4>

    <ul>
      <li>11 oz room temp water</li>
      <li>1 tsp granulated sugar</li>
      <li>1 packet (2 1/4 tsp) active dry yeast</li>
      <li>18 ounces all-purpose flour (about 3 1/2 cups)</li>
      <li>2 tsp salt</li>
      <li>1/8 tsp cream of tartar</li>
      <li>1/2 cup plus 3 Tbsp corn oil</li>
      <li>2 Tbsp melted unsalted butter </li>
      <li>Mozzarella cheese, grated (I like Galbani)</li>
      <li><Link to="/kathy-tomato-sauce/">Tomato sauce</Link></li>
    </ul>

    <h4>Instructions</h4>

    <ol>
      <li>Mix sugar, yeast, and water in bowl. Cover and let stand for 15 minutes</li>
      <li>Mix flour, salt, and cream of tartar in separate bowl</li>
      <li>After 15 mins, combine oil with flour mixture and yeast water. Mix with rubber spatula</li>
      <li>Knead for 90 seconds in stand mixer with dough hook</li>
      <li>Put the dough in a bowl greased with Pam. Cover and let rise til doubled for up to 6 hours (I only had to wait 4). Punch and let dough settle 15 mins</li>
      <li>Preheat oven to 450F</li>
      <li>Coat bottom of pan(s) with melted butter. I used 2 9-inch cake pans.</li>
      <li>Split dough into 2 portions, and push crust to edge as much as possible</li>
      <li>Put down cheese first then tomato sauce</li>
      <li>Bake for 25 minutes. Let rest for at least 5 minutes. Enjoy!</li>
    </ol>

    <h4>Notes:</h4>

    <ul>
      <li>Two 9-inch cake pans fit the whole recipe perfectly. However I couldn't manage to make a "wall"
        on the outer edge; the butter was too slippery.
      </li>
      <li>Butter may be used but I found online people use olive oil.</li>
      <li>25 minutes may not be enough time. I may add a couple minutes next time for a crispier crust.</li>
      <li>Use a kitchen scale to weigh the ingredients.</li>
      <li>Don't make the mistake of thinking the dough is too moist by adding extra flour. Following this recipe 
        exactly will yield perfect results.
      </li>
      <li>I used our family's tomato sauce but chunky home-made sauce tastes great as well</li>
    </ul>

  </Layout>
)

export default IndexPage
